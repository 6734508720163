import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Going to <a href="https://gateway.h2okinnovations.com/">H2Ok Innovations</a>.
        </p>
      </header>
    </div>
  );
}

export default App;
